@viewport { width : device-width; }

*,
*:before,
*:after {
	box-sizing: border-box;
}

html {
	height: 100%;
	font-size: $baseFontSize;
}

body {
	height: 100%;
	background: #FFF;
	color: $textColor;
	line-height: $baseLineHeight;
	font-family: $baseFontFamily;
	font-weight: 300;
}

img {
	max-width: 100%;
	height: auto;
	display: block;
}

.container {
	max-width: 1200px;
	margin: 0 auto;
	@include clearfix;
}
.row {
	@include clearfix;
}

#wrapper {
	height: 100%;
	position: relative;
	&.mobile-nav-open {
		overflow: hidden;
		#mobile-nav {
			
		}
		#wrapper-main {
			transform: translateX(-250px);
			box-shadow: 0 0 5px rgba(#000, 0.3);
		}
	}
}

#mobile-nav {
	width: 250px;
	max-width: 100%;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	background: $color1;
	color: #FFF;
	font-size: 16px;
	transition: 0.3s ease;
	.inner {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding: 30px 0;
		overflow: auto;
	}
	nav {
		ul {
			margin: 0;
			list-style: none;
			font-size: 16px;
			font-weight: 600;
			line-height: 1.2;
			text-transform: uppercase;
			li {
				display: block;
				margin: 0;
				position: relative;
				a {
					display: block;
					padding: 12px 50px 12px 20px;
					color: #FFF;
					&[href="#"] {
						cursor: default;
					}
				}
				ul {
					display: none;
					background: darken($color1, 5%);
					font-weight: 300;
					font-size: 13px;
					text-transform: none;
					li {
						ul {
							background: darken($color1, 10%);
						}
					}
				}
			}
		}
	}
	@include mbp(960) {
		display: none;
	}
}

#wrapper-main {
	height: 100%;
	position: relative;
	z-index: 2;
	background: #FFF;
	transition: 0.3s ease;
}

// MAIN / CONTENT
// ---------------

.main {
	padding: 60px 20px;
	background: #FFF;
	.main-title {
		margin: 0 0 60px;
		text-align: center;
		h1,
		h2 {
			margin: 0;
		}
	}
	.content {
		margin-bottom: 70px;
		@include clearfix;
	}
	.content-narrow {
		max-width: 840px;
		margin: 0 auto;
		@include clearfix;
	}
	&#about {
		background: fixed url(assets/img/grass2.jpg) no-repeat center center / cover;
		.main-title {
			h2 {
				color: #FFF;
			}
		}
		.content-narrow {
			background: rgba(#FFF, 0.95);
		}
		.entry {
			padding: 60px 5%;
		}
	}
	@include mbp(768) {
		padding: 60px 5%;
	}
	@include mbp(960) {
		padding: 100px 5% 80px;
		.main-title {
			h1,
			h2 {
				font-size: 48px;
			}
		}
		.content {
			width: percentage(840/1200);
			margin-bottom: 0;
			float: left;
		}
		.sidebar {
			width: percentage(300/1200);
			float: right;
		}
		&#about {
			.entry {
				padding: 80px 8%;
			}
		}
	}
}

// HOME
// ---------------

#hero {
	height: 100%;
	background: #000 url(assets/img/hero.jpg) no-repeat center center / cover;
	position: relative;
	.hero-text {
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
		padding: 20px 20px 50px;
		.text {
			color: #FFF;
			font-size: 20px;
			font-weight: 600;
			line-height: 1.3;
		}
		.quote {
			font-style: italic;
			font-size: 0.8em;
			p {
				margin: 0;
			}
			small {
				display: block;
				padding: 10px 0 0;
				font-size: 0.7em;
			}
		}
	}
	@include mbp(480) {
		.hero-text {
			padding: 0 5% 5%;
		}
	}
	@include mbp(768) {
		.hero-text {
			padding-bottom: 60px;
			.text {
				width: 50%;
				font-size: 30px;
			}
		}
	}
}

#demo {
	background: #FFF;
	.lightbox-gallery {
		margin: 10px 0 0;
	}
	@include mbp(768) {
		padding: 100px 5%;
		background: #FFF;
	}
}

// OTHER
// ---------------

.scrollable {
	overflow-x: auto;
	&.has-scroll {
		position: relative;
		overflow: hidden;
		.scrollable-inner {
			overflow-x: auto;
			&::-webkit-scrollbar {
				height: 12px;
			}
			&::-webkit-scrollbar-track {
				box-shadow: 0 0 2px rgba(0,0,0,0.15) inset;
				background: #f0f0f0;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 6px;
				background: #CCC;
			}
		}
		&:after {
			content: '';
			width: 50px;
			height: 100%;
			position: absolute;
			top: 0;
			left: 100%;
			border-radius: 10px 0 0 10px / 50% 0 0 50%;
			box-shadow: -5px 0 10px rgba(0, 0, 0, 0.25);
		}
	}
}

div.mfp-bg.rpjc-magnific {
	background: rgba(#000, 0.9);
	opacity: 1;
}

div.rpjc-magnific {
	.mfp-container {
		padding-top: 80px;
	}
	.mfp-close {
		width: 80px;
		height: 80px;
		padding: 0;
		margin: 0;
		background: none;
		opacity: 1;
		top: 0;
		right: 0;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
		span {
			width: 40px;
			height: 1px;
			display: block;
			margin-top: 20px;
			margin-left: 20px;
			background: #FFF;
			transition: all 0.3s ease-in-out;
			&:first-child {
				transform: translateY(21px) rotate(-45deg);
			}
			&:last-child {
				transform: rotate(45deg);
			}
		}
		&:hover {
			span {
				background: $color1;
			}
		}
	}
	.mfp-title {
		padding: 10px 0;
		font-size: 16px;
	}
	.mfp-counter {
		padding: 10px 0;
	}
	.mfp-arrow {
		width: auto;
		height: 50px;
		margin-top: -25px;
		cursor: pointer;
		opacity: 1;
		z-index: 1046;
		transform: scale(1);
		span {
			width: 30px;
			height: 1px;
			display: block;
			background: #FFF;
			position: relative;
			top: 10px;
			transition: all 0.3s ease-in-out;
		}
		&.mfp-arrow-left {
			left: 20px;
			span {
				&:first-child {
					transform: translateY(22px) rotate(45deg);
				}
				&:last-child {
					transform: rotate(-45deg);
				}
			}
		}
		&.mfp-arrow-right {
			right: 20px;
			span {
				&:first-child {
					transform: translateY(22px) rotate(-45deg);
				}
				&:last-child {
					transform: rotate(45deg);
				}
			}
		}
		&:before,
		&:after {
			display: none;
		}
		&:hover {
			span {
				background: $color1;
			}
		}
	}
}

.screen-reader {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal !important;
}

.contacts {
	margin: 0 0 $baseTypeSpacing;
	li {
		display: block;
		padding-left: 30px;
		margin: 0 0 10px;
		position: relative;
		&:before {
			width: 25px;
			display: block;
			position: absolute;
			left: 0;
			font-family: FontAwesome;
			text-align: center;
		}
		&.address {
			&:before {
				content: '\f041';
			}
		}
		&.phone {
			&:before {
				content: '\f095';
			}
		}
		&.fax {
			&:before {
				content: '\f1ac';
			}
		}
		&.email {
			&:before {
				content: '\f003';
			}
		}
		&.facebook {
			&:before {
				content: '\f09a';
			}
		}
		&.twitter {
			&:before {
				content: '\f099';
			}
		}
		&.youtube {
			&:before {
				content: '\f16a';
			}
		}
		&.instagram {
			&:before {
				content: '\f16d';
			}
		}
	}
}

.files {
	margin: 60px 0;
	ul {
		margin: 0;
		li {
			display: block;
			margin: 0;
			font-size: 16px;
			font-weight: 400;
			border-bottom: 1px solid $color1;
			a {
				display: block;
				padding: 20px 20px 20px 50px;
				position: relative;
				color: $color1;
				&:before {
					position: absolute;
					left: 20px;
					top: 17px;
					font-family: FontAwesome;
					font-size: 20px;
					content: '\f0a9';
				}
				&[href$=".pdf"] {
					&:before {
						content: '\f1c1';
					}
				}
				&[href$=".doc"],
				&[href$=".docx"] {
					&:before {
						content: '\f1c2';
					}
				}
				&[href$=".xls"],
				&[href$=".csv"] {
					&:before {
						content: '\f1c3';
					}
				}
				&[href$=".ppt"] {
					&:before {
						content: '\f1c4';
					}
				}
				&[href$=".jpg"],
				&[href$=".jpeg"],
				&[href$=".png"] {
					&:before {
						content: '\f1c5';
					}
				}
				&[href$=".mp3"],
				&[href$=".m4a"],
				&[href$=".wav"],
				&[href$=".wma"],
				&[href$=".flac"] {
					&:before {
						content: '\f1c7';
					}
				}
				&[href$=".zip"],
				&[href$=".gzip"] {
					&:before {
						content: '\f1c6';
					}
				}
				&[href$=".avi"],
				&[href$=".flv"],
				&[href$=".mov"],
				&[href$=".wmv"],
				&[href$=".mp4"] {
					&:before {
						content: '\f1c8';
					}
				}
				&:hover {
					text-decoration: none;
					color: #FFF;
					background: $color2;
				}
			}
			&:first-child {
				border-top: 1px solid $color1;
			}
		}
	}
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
	@include mbp(768) {
		ul {
			li {
				a {
					padding: 30px 20px 30px 85px;
					&:before {
						left: 30px;
						top: 20px;
						font-size: 30px;
					}
				}
			}
		}
	}
}

.accordion {
	margin: 60px 0;
	clear: both;
	border-bottom: 1px solid #DDD;
	.accordion-item {
		border-top: 1px solid #DDD;
		.accordion-handle {
			cursor: pointer;
			position: relative;
			transition: all 0.3s ease;
			h3 {
				padding: 15px 50px 15px 20px;
				margin: 0;
				font-size: 20px;
				font-family: $secondaryFontFamily;
				font-weight: 400;
				color: $color1;
				position: relative;
				&:after {
					font-family: FontAwesome;
					content: '\f107';
					font-size: 20px;
					position: absolute;
					right: 20px;
					top: 16px;
					transition: all 0.3s ease;
				}
			}
		}
		.accordion-content {
			padding: 30px 20px;
			font-size: 16px;
			@include clearfix;
		}
		&.selected {
			border-top: none;
			background-color: #F5F5F5;
			.accordion-handle {
				background: $color1;
				h3 {
					color: #FFF;
					&:after {
						transform: rotate(180deg);
					}
				}
			}
		}
	}
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
	@include mbp(768) {
		.accordion-item {
			.accordion-handle {
				h3 {
					padding: 25px 60px 25px 30px;
					&:after {
						top: 26px;
						right: 30px;
					}
				}
			}
			.accordion-content {
				padding: 45px 50px 30px;
			}
		}
	}
}

.tabbed-content {
	margin: 60px 0;
	clear: both;
	.tab-nav {
		margin: 0;
		font-weight: 700;
		font-size: 16px;
		font-family: $secondaryFontFamily;
		line-height: 1.2;
		li {
			display: block;
			margin: 0 0 5px;
			a {
				display: block;
				padding: 20px;
				color: #FFF;
				background: $color1;
				br {
					display: none;
				}
				&:hover {
					text-decoration: none;
				}
			}
			&.active {
				a {
					background: #EEE;
					color: $color1;
				}
			}
		}
	}
	.tab-window {
		.tab-panel {
			padding: 30px 20px;
			background: #EEE;
			font-size: 16px;
			@include clearfix;
		}
	}
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
	@include mbp(768) {
		.tab-nav {
			width: 100%;
			font-size: 20px;
			@include clearfix;
			li {
				margin: 0 1px 0 0;
				float: left;
				text-align: center;
				a {
					padding: 20px 30px;
					span {
						position: relative;
						&:after {
							content: '';
							width: 100%;
							height: 1px;
							display: block;
							background: $color1;
							position: absolute;
							bottom: -10px;
							left: 0;
							opacity: 0;
							transition: 0.2s ease;
						}
					}
				}
				&.active {
					a {
						span {
							&:after {
								opacity: 1;
							}
						}
					}
				}
			}
		}
		.tab-window {
			.tab-panel {
				padding: 45px 50px 30px;
			}
		}
	}
}

.lightbox-gallery {
	margin: 60px 0;
	@include clearfix;
	.gallery-item {
		margin-bottom: 1%;
		@include column(1/2, $cycle: 2, $gutter: 1);
		a {
			display: block;
			background: $color1;
			transition: 0.5s ease;
			&:hover {
				img {
					opacity: 0.8;
				}
			}
		}
		img {
			width: 100%;
			transition: 0.5s ease;
		}
		@include mbp(360) {
			@include column(1/3, $cycle: 3, $gutter: 1);
		}
		@include mbp(480) {
			@include column(1/4, $cycle: 4, $gutter: 1);
		}
		@include mbp(600) {
			@include column(1/4, $cycle: 4, $gutter: 1);
		}
	}
	&:first-child {
		margin-top: 0;
	}
}

// COLUMNS
// ---------------

.row {
	.one-half,
	.one-third,
	.two-third,
	.three-fourth,
	.one-fourth,
	.one-fifth,
	.two-fifth,
	.three-fifth,
	.four-fifth,
	.one-sixth,
	.five-sixth {
		margin-bottom: 50px;
	}
	.last {
		margin-right: 0 !important;
	}
}

@include mbp(480) {
	div[class^="column-group-"] {
		@include clearfix;
	}
	.column-group-2,
	.column-group-3,
	.column-group-4 {
		.column {
			@include column(1/2, $cycle: 2, $gutter: 5);
		}
	}
}

@include mbp(768) {
	.column-group-3,
	.column-group-4 {
		.column {
			@include column(1/3, $cycle: 3, $gutter: 5);
		}
	}
	.row {
		.one-half,
		.one-third,
		.two-third,
		.three-fourth,
		.one-fourth,
		.one-fifth,
		.two-fifth,
		.three-fifth,
		.four-fifth,
		.one-sixth,
		.five-sixth {
			margin-right: 5%;
			float: left;
		}
		.one-half { width: column-width(1/2, $gutter: 5); }
		.one-third { width: column-width(1/3, $gutter: 5); }
		.two-third { width: column-width(2/3, $gutter: 5); }
		.three-fourth { width: column-width(3/4, $gutter: 5); }
		.one-fourth { width: column-width(1/4, $gutter: 5); }
		.one-fifth { width: column-width(1/5, $gutter: 5); }
		.two-fifth { width: column-width(2/5, $gutter: 5); }
		.three-fifth { width: column-width(3/5, $gutter: 5); }
		.four-fifth { width: column-width(4/5, $gutter: 5); }
		.one-sixth { width: column-width(1/6, $gutter: 5); }
		.five-sixth { width: column-width(5/6, $gutter: 5); }
	}
}

@include mbp(1000) {
	.column-group-4 {
		.column {
			@include column(1/4, $cycle: 4, $gutter: 5);
		}
	}
}

.pace {
	display: none;
	@include mbp(960) {
		pointer-events: none;
		user-select: none;
		display: block;
		.pace-progress {
			background: $color1;
			position: fixed;
			z-index: 2000;
			top: 0;
			right: 100%;
			width: 100%;
			height: 2px;
		}
		.pace-progress-inner {
			display: block;
			position: absolute;
			right: 0px;
			width: 100px;
			height: 100%;
			opacity: 1.0;
			transform: rotate(3deg) translate(0px, -4px);
		}
		.pace-activity {
			display: block;
			position: fixed;
			z-index: 2000;
			top: 15px;
			right: 15px;
			width: 14px;
			height: 14px;
			border: solid 2px transparent;
			border-top-color: $color1;
			border-left-color: $color1;
			border-radius: 10px;
			animation: pace-spinner 400ms linear infinite;
		}
		&.pace-inactive {
			display: none;
		}
	}
}

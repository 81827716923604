
// TYPOGRAPHY
// ---------------

::-moz-selection { background: #000; color: #FFF; text-shadow: none; }
::selection { background: #000; color: #FFF; text-shadow: none; }

a {
	color: $color1;
	text-decoration: none;
	transition: all 0.2s ease-in-out;
	&:hover {
		text-decoration: underline;
	}
	&:focus {
		outline: none;
	}
}

h1, h2, h3, h4, h5, h6 {
	margin: $baseTypeSpacing*2 0 $baseTypeSpacing;
	font-family: $headingsFontFamily;
	font-weight: 700;
	color: $headingsColor;
	line-height: 1.2;
	text-rendering: optimizelegibility;
	&:first-child {
		margin-top: 0;
	}
}

h1 { font-size: 48px; }
h2 { font-size: 36px; }
h3 { font-size: 30px; }
h4 { font-size: 24px; margin: 0 0 10px; }
h5 { font-size: 20px; margin: 0 0 10px; }
h6 { font-size: 16px; margin: 0 0 10px; }

p {
	margin: 0 0 $baseTypeSpacing;
}

ul {
	padding: 0;
	margin: 0 0 $baseTypeSpacing $baseTypeSpacing;
	li {
		margin-bottom: 15px;
		ul {
			margin-top: 15px;
		}
	}
}

ol {
	padding: 0;
	margin: 0 0 $baseTypeSpacing $baseTypeSpacing;
	li {
		margin-bottom: 15px;
		ol {
			margin-top: 15px;
		}
	}
}

strong { font-weight: 700; }
em { font-style: italic; }
abbr { cursor: help; }

hr {
	height: 0;
	margin: 60px 0;
	border: 0;
	border-top: 1px solid $hrColor;
	border-bottom: 1px solid transparent;
}

blockquote {
	padding: 40px 0 40px - $baseTypeSpacing 50px;
	margin: 60px 0;
	background: none;
	border-left: 10px solid $color1;
	font-style: italic;
	font-size: 20px;
	color: $color1;
	footer,
	small,
	cite {
		display: block;
		margin: 0 0 $baseTypeSpacing;
		color: $headingsColor;
		font-weight: 700;
		font-size: 16px;
		font-style: normal;
		span {
			font-weight: normal;
		}
	}
}

address {
	display: block;
	margin-bottom: $baseTypeSpacing;
	font-style: normal;
}

// LINEA ARROWS FONT
// -----------------

@font-face {
	font-family: "linea-arrows-10";
	src:url("assets/fonts/linea-arrows/linea-arrows-10.eot");
	src:url("assets/fonts/linea-arrows/linea-arrows-10.eot?#iefix") format("embedded-opentype"),
		url("assets/fonts/linea-arrows/linea-arrows-10.woff") format("woff"),
		url("assets/fonts/linea-arrows/linea-arrows-10.ttf") format("truetype"),
		url("assets/fonts/linea-arrows/linea-arrows-10.svg#linea-arrows-10") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon]:before {
	font-family: "linea-arrows-10" !important;
	content: attr(data-icon);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
	font-family: "linea-arrows-10" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-arrows-anticlockwise:before { content: "\e000"; }
.icon-arrows-anticlockwise-dashed:before { content: "\e001"; }
.icon-arrows-button-down:before { content: "\e002"; }
.icon-arrows-button-off:before { content: "\e003"; }
.icon-arrows-button-on:before { content: "\e004"; }
.icon-arrows-button-up:before { content: "\e005"; }
.icon-arrows-check:before { content: "\e006"; }
.icon-arrows-circle-check:before { content: "\e007"; }
.icon-arrows-circle-down:before { content: "\e008"; }
.icon-arrows-circle-downleft:before { content: "\e009"; }
.icon-arrows-circle-downright:before { content: "\e00a"; }
.icon-arrows-circle-left:before { content: "\e00b"; }
.icon-arrows-circle-minus:before { content: "\e00c"; }
.icon-arrows-circle-plus:before { content: "\e00d"; }
.icon-arrows-circle-remove:before { content: "\e00e"; }
.icon-arrows-circle-right:before { content: "\e00f"; }
.icon-arrows-circle-up:before { content: "\e010"; }
.icon-arrows-circle-upleft:before { content: "\e011"; }
.icon-arrows-circle-upright:before { content: "\e012"; }
.icon-arrows-clockwise:before { content: "\e013"; }
.icon-arrows-clockwise-dashed:before { content: "\e014"; }
.icon-arrows-compress:before { content: "\e015"; }
.icon-arrows-deny:before { content: "\e016"; }
.icon-arrows-diagonal:before { content: "\e017"; }
.icon-arrows-diagonal2:before { content: "\e018"; }
.icon-arrows-down:before { content: "\e019"; }
.icon-arrows-down-double:before { content: "\e01a"; }
.icon-arrows-downleft:before { content: "\e01b"; }
.icon-arrows-downright:before { content: "\e01c"; }
.icon-arrows-drag-down:before { content: "\e01d"; }
.icon-arrows-drag-down-dashed:before { content: "\e01e"; }
.icon-arrows-drag-horiz:before { content: "\e01f"; }
.icon-arrows-drag-left:before { content: "\e020"; }
.icon-arrows-drag-left-dashed:before { content: "\e021"; }
.icon-arrows-drag-right:before { content: "\e022"; }
.icon-arrows-drag-right-dashed:before { content: "\e023"; }
.icon-arrows-drag-up:before { content: "\e024"; }
.icon-arrows-drag-up-dashed:before { content: "\e025"; }
.icon-arrows-drag-vert:before { content: "\e026"; }
.icon-arrows-exclamation:before { content: "\e027"; }
.icon-arrows-expand:before { content: "\e028"; }
.icon-arrows-expand-diagonal1:before { content: "\e029"; }
.icon-arrows-expand-horizontal1:before { content: "\e02a"; }
.icon-arrows-expand-vertical1:before { content: "\e02b"; }
.icon-arrows-fit-horizontal:before { content: "\e02c"; }
.icon-arrows-fit-vertical:before { content: "\e02d"; }
.icon-arrows-glide:before { content: "\e02e"; }
.icon-arrows-glide-horizontal:before { content: "\e02f"; }
.icon-arrows-glide-vertical:before { content: "\e030"; }
.icon-arrows-hamburger1:before { content: "\e031"; }
.icon-arrows-hamburger-2:before { content: "\e032"; }
.icon-arrows-horizontal:before { content: "\e033"; }
.icon-arrows-info:before { content: "\e034"; }
.icon-arrows-keyboard-alt:before { content: "\e035"; }
.icon-arrows-keyboard-cmd:before { content: "\e036"; }
.icon-arrows-keyboard-delete:before { content: "\e037"; }
.icon-arrows-keyboard-down:before { content: "\e038"; }
.icon-arrows-keyboard-left:before { content: "\e039"; }
.icon-arrows-keyboard-return:before { content: "\e03a"; }
.icon-arrows-keyboard-right:before { content: "\e03b"; }
.icon-arrows-keyboard-shift:before { content: "\e03c"; }
.icon-arrows-keyboard-tab:before { content: "\e03d"; }
.icon-arrows-keyboard-up:before { content: "\e03e"; }
.icon-arrows-left:before { content: "\e03f"; }
.icon-arrows-left-double-32:before { content: "\e040"; }
.icon-arrows-minus:before { content: "\e041"; }
.icon-arrows-move:before { content: "\e042"; }
.icon-arrows-move2:before { content: "\e043"; }
.icon-arrows-move-bottom:before { content: "\e044"; }
.icon-arrows-move-left:before { content: "\e045"; }
.icon-arrows-move-right:before { content: "\e046"; }
.icon-arrows-move-top:before { content: "\e047"; }
.icon-arrows-plus:before { content: "\e048"; }
.icon-arrows-question:before { content: "\e049"; }
.icon-arrows-remove:before { content: "\e04a"; }
.icon-arrows-right:before { content: "\e04b"; }
.icon-arrows-right-double:before { content: "\e04c"; }
.icon-arrows-rotate:before { content: "\e04d"; }
.icon-arrows-rotate-anti:before { content: "\e04e"; }
.icon-arrows-rotate-anti-dashed:before { content: "\e04f"; }
.icon-arrows-rotate-dashed:before { content: "\e050"; }
.icon-arrows-shrink:before { content: "\e051"; }
.icon-arrows-shrink-diagonal1:before { content: "\e052"; }
.icon-arrows-shrink-diagonal2:before { content: "\e053"; }
.icon-arrows-shrink-horizonal2:before { content: "\e054"; }
.icon-arrows-shrink-horizontal1:before { content: "\e055"; }
.icon-arrows-shrink-vertical1:before { content: "\e056"; }
.icon-arrows-shrink-vertical2:before { content: "\e057"; }
.icon-arrows-sign-down:before { content: "\e058"; }
.icon-arrows-sign-left:before { content: "\e059"; }
.icon-arrows-sign-right:before { content: "\e05a"; }
.icon-arrows-sign-up:before { content: "\e05b"; }
.icon-arrows-slide-down1:before { content: "\e05c"; }
.icon-arrows-slide-down2:before { content: "\e05d"; }
.icon-arrows-slide-left1:before { content: "\e05e"; }
.icon-arrows-slide-left2:before { content: "\e05f"; }
.icon-arrows-slide-right1:before { content: "\e060"; }
.icon-arrows-slide-right2:before { content: "\e061"; }
.icon-arrows-slide-up1:before { content: "\e062"; }
.icon-arrows-slide-up2:before { content: "\e063"; }
.icon-arrows-slim-down:before { content: "\e064"; }
.icon-arrows-slim-down-dashed:before { content: "\e065"; }
.icon-arrows-slim-left:before { content: "\e066"; }
.icon-arrows-slim-left-dashed:before { content: "\e067"; }
.icon-arrows-slim-right:before { content: "\e068"; }
.icon-arrows-slim-right-dashed:before { content: "\e069"; }
.icon-arrows-slim-up:before { content: "\e06a"; }
.icon-arrows-slim-up-dashed:before { content: "\e06b"; }
.icon-arrows-square-check:before { content: "\e06c"; }
.icon-arrows-square-down:before { content: "\e06d"; }
.icon-arrows-square-downleft:before { content: "\e06e"; }
.icon-arrows-square-downright:before { content: "\e06f"; }
.icon-arrows-square-left:before { content: "\e070"; }
.icon-arrows-square-minus:before { content: "\e071"; }
.icon-arrows-square-plus:before { content: "\e072"; }
.icon-arrows-square-remove:before { content: "\e073"; }
.icon-arrows-square-right:before { content: "\e074"; }
.icon-arrows-square-up:before { content: "\e075"; }
.icon-arrows-square-upleft:before { content: "\e076"; }
.icon-arrows-square-upright:before { content: "\e077"; }
.icon-arrows-squares:before { content: "\e078"; }
.icon-arrows-stretch-diagonal1:before { content: "\e079"; }
.icon-arrows-stretch-diagonal2:before { content: "\e07a"; }
.icon-arrows-stretch-diagonal3:before { content: "\e07b"; }
.icon-arrows-stretch-diagonal4:before { content: "\e07c"; }
.icon-arrows-stretch-horizontal1:before { content: "\e07d"; }
.icon-arrows-stretch-horizontal2:before { content: "\e07e"; }
.icon-arrows-stretch-vertical1:before { content: "\e07f"; }
.icon-arrows-stretch-vertical2:before { content: "\e080"; }
.icon-arrows-switch-horizontal:before { content: "\e081"; }
.icon-arrows-switch-vertical:before { content: "\e082"; }
.icon-arrows-up:before { content: "\e083"; }
.icon-arrows-up-double-33:before { content: "\e084"; }
.icon-arrows-upleft:before { content: "\e085"; }
.icon-arrows-upright:before { content: "\e086"; }
.icon-arrows-vertical:before { content: "\e087"; }

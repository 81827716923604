@keyframes pace-spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes fadeInRightBig {
	from {
		opacity: 0;
		transform: translate3d(2000px, 0, 0);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

@keyframes fadeInRight {
	from {
		opacity: 0;
		transform: translate3d(50px, 0, 0);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 50px, 0);
	}
	to {
		opacity: 1;
		transform: none;
	}
}
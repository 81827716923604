#footer-2 {
	padding: 30px 20px 0;
	background: $color1;
	font-weight: 400;
	text-align: center;
	color: #FFF;
	a {
		color: #FFF;
		text-decoration: none;
		&:hover {
			opacity: 0.7;
		}
	}
	.footer-l {
		margin: 0 0 10px;
		nav {
			ul {
				margin: 0;
				text-transform: uppercase;
				font-size: 13px;
				font-weight: 700;
				li {
					display: inline-block;
					margin: 0 10px;
					ul {
						display: none;
					}
				}
			}
		}
	}
	.footer-r {
		margin-bottom: 30px;
		.copyright {
			font-size: 11px;
			span {
				padding: 0 10px;
			}
		}
	}
	@include mbp(768) {
		padding: 30px 5% 0;
		.footer-l {
			@include column(0.6, $cycle: 2, $gutter: 5);
			margin-bottom: 30px;
			nav {
				ul {
					@include clearfix;
					li {
						margin: 0 20px 0 0;
						float: left;
					}
				}
			}
		}
		.footer-r {
			@include column(0.4, $cycle: 2, $gutter: 5);
			margin-bottom: 30px;
			text-align: right;
		}
	}
	@include mbp(960) {
		.footer-l {
			nav {
				ul {
					li {
						margin-right: 30px;
					}
				}
			}
		}
	}
}
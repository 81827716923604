#header-2 {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 500;
	padding: 30px 20px;
	.container {
		max-width: 1720px;
		position: relative;
	}
	.logo {
		width: 200px;
		float: left;
		img {
			margin: 0 auto;
		}
	}
	nav {
		display: none;
	}
	.toggle-mobile-nav {
		width: 32px;
		margin: 3px 0 0 0;
		cursor: pointer;
		float: right;
		span {
			display: block;
			height: 4px;
			margin-top: 5px;
			background: #FFF;
			transition: 0.15s;
			&:first-child {
				margin-top: 0;
			}
		}
	}
	@include mbp(768) {
		padding: 30px 5%;
		.logo {
			width: 300px;
		}
		.toggle-mobile-nav {
			margin-top: 10px;
		}
	}
	@include mbp(960) {
		padding: 35px 5%;
		.logo {

		}
		.toggle-mobile-nav {
			display: none;
		}
		nav {
			display: block;
			padding-top: 11px;
			float: right;
			ul {
				margin: 0;
				line-height: 1.2;
				font-size: 16px;
				font-weight: 700;
				text-transform: uppercase;
				@include clearfix;
				li {
					display: block;
					margin: 0 0 0 30px;
					float: left;
					a {
						color: #FFF;
						&:hover {
							text-decoration: none;
							color: $color1;
						}
					}
					ul {
						display: none;
					}
				}
			}
		}
	}
	@include mbp(960) {
		.logo {
			width: 350px;
		}
		nav {
			padding-top: 15px;
		}
	}
}

// FORMS
// ---------------

form {
	font-size: 16px;
}

fieldset {
	margin-bottom: $baseTypeSpacing;
	padding: 0;
	border: none;
	legend {
		width: 100%;
		display: block;
		color: $headingsColor;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 20px;
		border-bottom: 1px solid $hrColor;
		margin: 0 0 $baseTypeSpacing;
		padding: 0;
	}
}

#{$all-text-inputs},
select[multiple=multiple],
select {
	appearance: none;
	width: 100%;
	height: 48px;
	padding: 0 10px;
	outline: none;
	font-size: 16px;
	font-weight: 300;
	font-family: $baseFontFamily;
	color: $textColor;
	border: none;
	background: #EEE;
	border-radius: 0;
	box-shadow: none;
	line-height: 1;
	&.wpcf7-not-valid {
		border-color: red;
	}
}

select {
	height: 48px;
	padding: 0 40px 0 15px;
	background: #EEE url(assets/img/select.png) no-repeat right center;
	@include hidpi(2) {
		background-image: url(assets/img/select@2x.png);
		background-size: 33px 8px;
	}
}

input[type="radio"],
input[type="checkbox"] {
	margin: 0;
	cursor: pointer;
}

.checkbox-group,
.radio-group {
	label {
		font-weight: normal;
	}
}

.checkbox-group-inline,
.radio-group-inline {
	label {
		display: inline-block;
		margin-right: 20px;
	}
}

input[type="search"] {
	appearance: none;
}

textarea {
	width: 100%;
	height: 150px;
	padding: 10px;
	resize: vertical;
	overflow: auto;
}

select[multiple],
select[size] {
	height: auto;
}

::-moz-focus-inner {
	border: 0;
	padding: 0;
}

#{$all-button-inputs},
button,
.button,
.btn {
	padding: 15px 25px;
	display: inline-block;
	font-weight: 700;
	font-family: $baseFontFamily;
	vertical-align: middle;
	cursor: pointer;
	color: $color1;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 13px;
	line-height: 1;
	border: 2px solid $color1;
	border-radius: 0;
	outline: none;
	background: none;
	appearance: none;
	letter-spacing: 2px;
	transition: all 0.2s ease-in-out;
	&.btn-fa-left {
		.fa {
			margin-right: 5px;
		}
	}
	&.btn-fa-right {
		.fa {
			margin-left: 5px;
		}
	}
	&:hover,
	&:active {
		color: #FFF;
		text-decoration: none;
		background: $color1;
	}
	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}
}

input[type="image"] {
	border: 0;
}

.form-row {
	margin: 0 0 10px;
}

.form-inline {
	.form-row {
		@include clearfix;
		label {
			width: 30%;
			padding-top: 5px;
			float: left;
		}
		.inputs {
			margin-left: 33%;
			label {
				width: auto;
				float: none;
			}
		}
	}
	.form-actions {
		padding-left: 33%;
	}
}

.form-help {
	display: block;
	padding-top: 3px;
	color: #999;
	font-style: italic;
	font-size: 0.9em;
	clear: both;
}

.form-actions {
	input,
	button {
		margin: 0 5px 0 0;
	}
}

div.wpcf7 img.ajax-loader {
	display: inline-block;
	margin-left: 10px;
}

.alert-success,
.alert-notice,
.alert-error,
.gform_confirmation_message,
div.wpcf7-mail-sent-ok,
div.wpcf7-validation-errors,
#cancel-comment-reply-link {
	display: block;
	margin: 0 0 $baseTypeSpacing;
	padding: 10px 20px;
	border: none;
	border-radius: 0;
	font-size: 16px;
	font-weight: 400;
	background: #F7F6F7;
	color: $textColor;
	border-left: 4px solid transparent;
	ul {
		margin: 0;
		li {
			display: block;
			margin: 0;
		}
	}
	.fa {
		margin-right: 3px;
	}
}

.alert-success,
div.wpcf7-mail-sent-ok,
.gform_confirmation_message {
	border-left-color: #8FAE1B;
	.fa {
		color: #8FAE1B;
	}
}

.alert-notice {
	border-left-color: #1E85BE;
	.fa {
		color: #1E85BE;
	}
}

.alert-error,
div.wpcf7-validation-errors,
#cancel-comment-reply-link {
	border-left-color: #B81C23;
	.fa {
		color: #B81C23;
	}
}

span.wpcf7-not-valid-tip {
	display: block;
	padding: 5px 0 0;
	color: red;
	font-size: 0.8em;
}

#contact-form-messages {
	.alert {
		margin-top: 20px;
	}
}